import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import OurProductsNavigation from "./components/OurProductsNavigation";
import SapientiaAnalytics from "./components/SapientiaAnalytics";
import SapientiaMobile from "./components/SapientiaMobile";
import FormacaoSapieduca from "./components/FormacaoSapieduca";
import {
  Button,
  OurProductsContainer,
  Subtitle,
  Title
} from "./OurProducts.styles";

const OurProducts = () => {
  const [currentContent, setCurrentContent] = useState(0);

  const renderTabContent = () => {
    switch (currentContent) {
      case 0:
        return <SapientiaMobile />;
      case 1:
        return <SapientiaAnalytics />;
      default:
        return <FormacaoSapieduca />;
    }
  };

  return (
    <OurProductsContainer>
      <div>
        <Subtitle >VALIDAÇÃO</Subtitle>
        <Title >Nossos produtos</Title>
        <OurProductsNavigation
          currentContent={currentContent}
          setCurrentContent={setCurrentContent}
        />
      </div>
      {renderTabContent()}
      <Link
        activeClass="active"
        to="contact"
        spy
        smooth
        offset={100}
        duration={500}
        className="link-contact"
        style={{
          textDecoration: "none"
        }}
      >
        <Button>Entre em contato</Button>
      </Link>
    </OurProductsContainer>
  );
};

export default OurProducts;
