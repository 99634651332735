import React, { useState } from "react";
import Chevron from "../../assets/Chevron";
import Aluno from "../TabContent/Aluno";
import Diretor from "../TabContent/Diretor";
import Professor from "../TabContent/Professor";
import TabNavigation from "../TabNavigation/TabNavigation";
import {
  Button,
  SolutionsContainer,
  Subtitle,
  TabContentContainer,
  Title
} from "./Solutions.styles";

const Solutions = () => {
  const [currentContent, setCurrentContent] = useState(0);

  const renderTabContent = () => {
    switch (currentContent) {
      case 0:
        return <Diretor />;
      case 1:
        return <Professor />;
      default:
        return <Aluno />;
    }
  };

  return (
    <SolutionsContainer id="solutions">
      <Button style={{ backgroundColor: '#F23C8E' }}>
        <Chevron />
      </Button>
      <div>
        <Subtitle>SOLUÇÕES</Subtitle>
        <Title>Como funciona para cada perfil?</Title>
      </div>
      <TabContentContainer>
        <TabNavigation
          currentContent={currentContent}
          setCurrentContent={setCurrentContent}
        />
        {renderTabContent()}
      </TabContentContainer>
    </SolutionsContainer>
  );
};

export default Solutions;
