/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const SolutionsContainer = styled.section`
  min-height: 620px;
  background-color: #2a5ec7;
  border-radius: 0px 0px 212px 0px;
  box-sizing: border-box;
  padding: 105px 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media (max-width: 1024px) {
    padding: 105px 24px 0;
    height: auto;
    min-height: 135vh;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 1024px) {
    height: auto;

    section {
      flex-direction: column;
    }
  }
`;

export const Subtitle = styled.span`
  font-style: roboto;
  font-weight: 500;
  font-size: 17px;
  line-height: 184.9%;
  color: #35ccd3;
  margin: 0;
`;

export const Title = styled.h2`
  font-style: roboto;
  font-weight: 700;
  font-size: 36px;
  line-height: 138.7%;
  color: #ffffff;
  margin: 0;
`;

export const Button = styled.button`
  width: 56px;
  height: 56px;
  background: #35ccd3;
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
`;

export const TabContentContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
