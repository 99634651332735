/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Header = styled.header`
  height: 760px;
  background-color: white;
  position: relative;

  & > .header-background {
    position: absolute;
    width: 100%;
    bottom: -300px;
  }

  @media (max-width: 1024px) {
    height: 100vh;
    background-position: center;
    background-size: initial;
  }
`;

export const HeaderContainer = styled.section`
  height: 100%;
  display: flex;
  padding: 0 112px;

  @media (max-width: 1024px) {
    padding: 0 24px;
  }

  & > div {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 9;

    @media (max-width: 1024px) {
      &:first-of-type {
        width: 100%;
        z-index: 99;
      }

      &:last-of-type {
        display: none;
      }
    }
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 66px;
  color: #3366cc;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 46px;
  }
`;

export const Subtitle = styled.h2`
  width: 350px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7a7786;
  margin: 16px 0 40px 0;
  padding: 0;
`;

export const Button = styled.button`
  width: 277px;
  height: 44px;
  background-color: #ea5f9e;
  border-radius: 24px;
  padding: 14px 0;
  border: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  &:hover {
    filter: saturate(0.9);
  }
`;
