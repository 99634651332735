import React, { useState, useEffect } from "react";
import ComputadorImg from "../../../assets/computador.png";
import RoundedIcon from "../../../assets/RoundedIcon";
import VideoPreview from "../../VideoPreview/VideoPreview";
import {
  GridItem,
  SectionContainer,
  SectionGrid,
  SectionTitle
} from "../OurProducts.styles";

const video = require("./video.mp4");

const FormacaoSapieduca = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <SectionContainer style={{ position: "relative", fontFamily: "Roboto" }}>
      <div style={{ position: "relative" }}>
        <SectionTitle style={{ fontFamily: "Poppins", color: "#3366CC" }}>Formação Sapieduca</SectionTitle>
        <p>
        Descubra a Formação Sapieduca e potencialize sua prática educacional! Nossas formações abrangem o uso da plataforma Sapieduca, instrumentos avaliativos, gamificação, competências empreendedoras, metodologias ativas e muito mais.
       </p>
       <p>
       Principais temas abordados:
       </p>
        <SectionGrid>
          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Uso da Plataforma Sapieduca:</h3>
              <p>
              Aprenda a utilizar nossa plataforma inovadora, explorando seus recursos e aprendendo como obter relatórios de desempenho detalhados para aprimorar sua prática pedagógica.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Instrumentos Avaliativos:</h3>
              <p>
              focado na realização de avaliações eficazes dos alunos, utilizando as metodologias adequadas. Aborda os diferentes tipos de avaliações e demonstra como o Sapieduca pode facilitar esse processo.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Gamificação na Educação:</h3>
              <p>
              Conheça os conceitos fundamentais de gamificação para aplicação em sala de aula. Apresentamos o Framework Octalysis, que explora os drives motivacionais e como o professor pode utilizar esses conhecimentos para maximizar os recursos gamificados da plataforma.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Desenvolvimento de Competências Empreendedoras:</h3>
              <p>
              Aprenda a utilizar o design thinking e a metodologia da Aprendizagem Baseada em Problemas para desenvolver as competências e habilidades empreendedoras nos alunos, preparando-os para os desafios do futuro.

              </p>
            </div>
          </GridItem>
        </SectionGrid>
      </div>

      
      {!isMobile && (
        <div style={{ position: "absolute", top: "10%", left: "70%" }}>
          
          <img
            src={ComputadorImg}
            alt="Computador"
            style={{ width: "100%", maxWidth: "600px", margin: "0 auto", position: "relative" }}
          />

          
          <div style={{ position: "absolute", top: "23%", left: "15%", width: "70%", height: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <VideoPreview
              src={video}
            />
          </div>
        </div>
      )}

      
      {isMobile && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <VideoPreview src={video} />
        </div>
      )}
    </SectionContainer>
  );
};

export default FormacaoSapieduca;
