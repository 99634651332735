import React from "react";
import { OurProductsProps } from "../OurProducts.types";
import { Tab, TabContainer } from "./OurProductsNavigation.styles";

const OurProductsNavigation: React.FunctionComponent<OurProductsProps> = ({
  currentContent,
  setCurrentContent
}) => {
  return (
    <TabContainer>
      <Tab
        onClick={() => setCurrentContent(0)}
        className={currentContent === 0 ? "active" : ""}
        style={{ backgroundColor: currentContent === 0 ? "#F23C8E" : "transparent" }}
      >
        <span>Plataforma</span>
      </Tab>
      <Tab
        onClick={() => setCurrentContent(1)}
        className={currentContent === 1 ? "active" : ""}
        style={{ backgroundColor: currentContent === 1 ? "#F23C8E" : "transparent" }}
      >
        <span>Analytics</span>
      </Tab>
      <Tab
        onClick={() => setCurrentContent(2)}
        className={currentContent === 2 ? "active" : ""}
        style={{ backgroundColor: currentContent === 2 ? "#F23C8E" : "transparent" }}
      >
        <span>Formação</span>
      </Tab>
    </TabContainer>
  );
};

export default OurProductsNavigation;
