import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { PrizesContainer, Subtitle, Title } from "./Prizes.styles";
import HelsinkiImage from "../../assets/Helsinki.png";

const Prizes = () => {
  return (
    <PrizesContainer id="prizes">
      <div>
        <Subtitle>PRÊMIOS</Subtitle>
        <Title>
          Um pouco da nossa <br /> história
        </Title>
      </div>
      <Carousel style={{ width: "100%" }}>
        <Carousel.Item>
          <img className="d-block w-100" src={HelsinkiImage} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={HelsinkiImage} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={HelsinkiImage} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
    </PrizesContainer>
  );
};

export default Prizes;
