import React from "react";
import About from "../../components/About/About";
import Blog from "../../components/Blog/Blog";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import MainHeader from "../../components/Header/Header";
import OurProducts from "../../components/OurProducts/OurProducts";
import Prizes from "../../components/Prizes/Prizes";
import Solutions from "../../components/Solutions/Solutions";
import Team from "../../components/Team/Team";
import Validation from "../../components/Validation/Validation";

const Home = () => {
  return (
    <>
      <MainHeader />
      <About />
      <Solutions />
      <OurProducts />
      <Validation />
      <Prizes />
      <Team />
      <Blog />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
