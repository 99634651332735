/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const TabsContainer = styled.section`
  margin-top: 67px;
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Tab = styled.button<{ onClick: () => void }>`
  width: 312px;
  height: 51px;
  background: #3366cc;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s ease-in-out;

  &.active {
    background: #35ccd3;
    width: 384px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  & > span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
  }

  &:not(:last-of-type) {
    margin-bottom: 18px;
  }
`;
