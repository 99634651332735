/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import styled from "styled-components";

export const TeamCardItem = styled.div`
    width: 677px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
        height: 166px;
        width: 166px;
        border-radius: 50%;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        margin-top: 71px;
        overflow: hidden;
    };

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #3366CC;
        padding: 0;
        margin: 0;
    };

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #505050;
    };
`;