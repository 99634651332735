import React, { FormEvent, useState } from "react";
import axios from "axios";
import Select from "react-select";
import {
  Button,
  ContactContainer,
  Input,
  InputContainer,
  InputGroup,
  Subtitle,
  TextField,
  Title
} from "./Contact.styles";

type OptionType = {
  value: string;
  label: string;
};

const options = [
  { value: "Professor(a)", label: "Professor(a)" },
  { value: "Diretor(a)", label: "Diretor(a)" },
  { value: "Coordenador(a)", label: "Coordenador(a)" },
  { value: "Outro", label: "Outro" }
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    type: { value: "", label: "" },
    message: ""
  });

  const [selectedOption, setSelectedOption] = useState<OptionType>(options[0]);

  const handleChange = (option: OptionType) => {
    setFormData({ ...formData, type: option });
    setSelectedOption(option);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (formData.type.value.length === 0) {
      // eslint-disable-next-line no-useless-return
      return;
    } else {
      const response = await axios.post(
        "https://api2.sapientia.education/misc/contact-us-website",
        formData
      );
    }
  };

  return (
    <div style={{ marginTop: '250px', alignItems: 'center' }}>
      <Subtitle >CONTATO</Subtitle>
      <Title>Fale com os nossos consultores =)</Title>
      <div style={{ marginTop: '-150px' }}>
      <div>
        <ContactContainer id="contact" onSubmit={handleSubmit} style={{ backgroundColor: '#3366CC', padding: '20px', border: '2px solid #3366CC', borderRadius: '50px' }}>
          <InputGroup>
            <div>
              <InputContainer>
                <p>Nome completo</p>
                <Input
                  type="text"
                  required
                  value={formData.name}
                  name="name"
                  id="name"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </InputContainer>
              <InputContainer>
                <p>Endereço de e-mail</p>
                <Input
                  type="email"
                  required
                  value={formData.email}
                  name="email"
                  id="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </InputContainer>
              <InputContainer>
                <Select
                  options={options}
                  classNamePrefix="sapientia-select"
                  placeholder="Qual função você exerce na escola?"
                  name="options"
                  id="options"
                  onChange={() => handleChange(selectedOption)}
                  styles={{ control: base => ({ ...base, backgroundColor: '#fff', color: '#000' }) }}
                />
              </InputContainer>
            </div>
            <div>
              <TextField
                placeholder="Escreva sua mensagem"
                required
                name="message"
                value={formData.message}
                id="message"
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              />
            </div>
          </InputGroup>
          <Button type="submit" style={{ backgroundColor: '#F23C8E', color: '#fff' }}>Enviar Mensagem</Button>
        </ContactContainer>
      </div>
    </div>
    </div>
  );
};

export default Contact;
