/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  width: 1000px;
  margin-top: 60px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  & > p {
    padding: 0;
    margin: 0;
    font-style: roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & > ul {
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-right: 46px;
    }

    & > li {
      list-style: none;
      display: flex;
      align-items: center;

      font-style: roboto;
      font-weight: 700;
      font-size: 16px;
      line-height: 201.5%;
      color: #ffffff;
      

      & > svg {
        margin-right: 20px;
      }
    }
  }
`;
