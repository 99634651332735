import React from "react";
import check from "../../assets/check.png";
import { Container, List } from "./TabContent.styles";

const Diretor: React.FunctionComponent = () => {
  const checkIconStyle = {
    width: "24px",
    height: "24px",
    marginRight: '8px', // Adicionado para espaço entre o ícone e o texto
  };

  const containerStyle = {
    marginLeft: '10px',
    color: "white",
    fontWeight: "bold", // Adicionando negrito
  };

  const listItemTextStyle = {
    
  };

  const ulStyle = {
    marginTop: "20px", // Adicionando margem para mover os ícones para baixo
  };

  return (
    <Container style={containerStyle}>
      <div>
        <p>
        Os coordenadores pedagógicos têm acesso ao nosso dashboard com relatórios personalizados sobre o desempenho acadêmico da instituição de ensino. Oferecemos diagnósticos de aprendizagem detalhados, incluindo dados sobre estudantes, turmas, áreas de conhecimento, componentes curriculares, notas, frequências e habilidades desenvolvidas de acordo com a BNCC.
        </p>
      </div>
      <List>
        <ul style={ulStyle}>
          <li style={{ ...listItemTextStyle, marginBottom: "8px" }}><img src={check} alt="check" style={checkIconStyle} /><span>Dashboards</span></li>
          <li style={{ ...listItemTextStyle, marginBottom: "8px" }}><img src={check} alt="check" style={checkIconStyle} /><span>Learning Analytics</span></li>
        </ul>
        <ul style={ulStyle}>
          <li style={{ ...listItemTextStyle, marginBottom: "8px" }}><img src={check} alt="check" style={checkIconStyle} /><span>Diagnósticos</span></li>
          <li style={{ ...listItemTextStyle, marginBottom: "8px" }}><img src={check} alt="check" style={checkIconStyle} /><span>Controle acadêmico</span></li>
        </ul>
      </List>
    </Container>
  );
};

export default Diretor;
