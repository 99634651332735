/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Slider from "react-slick";
import TeamCard from "../TeamCard/TeamCard";

import { TeamContainer, Subtitle, Title } from "./Team.styles";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import team1 from "../../assets/team1.jpeg";
import team2 from "../../assets/team2.jpeg";
import team3 from "../../assets/team3.jpeg";
import team4 from "../../assets/team4.jpeg";

const Arrow = (props: any) => {
  const className = props.type === "next" ? "next" : "prev";
  return (
    <a
      role="button"
      onClick={props.onClick}
      className={`carousel-control-${className}`}
    >
      <span
        aria-hidden="true"
        className={`carousel-control-${className}-icon`}
      />
      <span className="visually-hidden">Previous</span>
    </a>
  );
};

function appendDots(dots: any) {
  return (
    <div className="carousel-indicators">
      <ul>{dots}</ul>
    </div>
  );
}

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: true,
  centerMode: true,
  centerPadding: "0",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};

const Team = () => {
  return (
    <TeamContainer id="depoimentos">
      <div>
        <Subtitle>EQUIPE</Subtitle>
        <Title>
          Conheça quem faz <br /> acontecer
        </Title>
      </div>
      <div>
        <Slider
          {...settings}
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          appendDots={appendDots}
          infinite
          focusOnSelect
        >
          <TeamCard
            src={team1}
            name="Jorge Elô"
            title="Diretor Executivo"
            description="É formado em Licenciatura plena em História e possui mestrado em Literatura e Interculturalidade, pela UEPB. Possui experiência no ensino básico, sendo um dos criadores da plataforma Sapieduca - Plataforma Gamificada de Avaliação Ativa, Engajamento e Projeto de Vida. O profissional compõe a equipe pedagógica de criação e validação de novas soluções."
          />
          <TeamCard
            src={team3}
            name="Roberto Nascimento"
            title="Diretor de Tecnologia"
            description="Possui experiência em sistemas distribuídos, segurança da informação e computação em nuvem. Apaixonado por como a tecnologia pode colaborar na transformação da educação, Roberto atua no Sapieduca em diferentes vertentes que necessitam de tecnologia, tendo como principais atividades o design/arquitetura da solução, implementação/desenvolvimento, análise, manutenção, etc. Atualmente é graduando em Ciência da Computação na UFCG e trabalha como Engenheiro de Software na BairesDev."
          />
          <TeamCard
            src={team2}
            name="Ariel Roque"
            title="Diretor de Pesquisa"
            description="É graduado em Ciência da Computação pela UFCG e membro do Laboratório de Sistemas Distribuídos onde atua em projetos com computação confidencial, sistemas distribuídos e desenvolvimento de sistemas. No Sapieduca, é responsável pela ideação e pesquisa dos produtos e desenvolvimento front-end da solução."
          />
          <TeamCard
            src={team4}
            name="Jean Tomceac"
            title="Diretor de Estratégia"
            description="15 anos de experiência nas áreas de Tecnologia, Educação e Inovação. Professor, mentor e consultor de projetos e startups de Tecnologia, Educação e Impacto Social. Experiência profissional nacional e internacional com atividades em instituições de referência como: SEBRAE/SP, HundrED.Org, CIEB, CREA DIGITAL (Gov. da Colômbia – MinTIC/Min. Cultura), SENAC/SP, comKids Festival - Prix Jeunesse Iberoamericano e Comkids Interativo, Universidade Anhembi-Morumbi, Global Game Jam, Colégio Visconde de Porto Seguro e Escola da Vila"
          />
        </Slider>
      </div>
    </TeamContainer>
  );
};

export default Team;
