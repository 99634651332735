/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const VideoPreviewContainer = styled.section`
  position: absolute;
  right: 7.5%;
  width: 369px;
  height: 221px;
  background-color: #222222;
  border-radius: 30px;

  @media (max-width: 1024px) {
    position: relative;
    right: unset;
  }

  video {
    border-radius: inherit;
  }

  .hexagon {
    width: 45px;
    height: 24px;
    background-color: #ffffff;
    position: absolute;
    left: 43%;
    top: 49%;
    transform: translateY(-50%) rotate(90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-left: 10px solid #35ccd3;

      transform: rotate(-90deg);
    }
  }

  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
  }

  .hexagon:before {
    bottom: 100%;
    border-bottom: 18.6px solid #ffffff;
  }

  .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 18.6px solid #ffffff;
  }
`;
