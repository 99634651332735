import React from "react";
import { AboutContainer, Subtitle, Title } from "./About.styles";

const About = () => {
  return (
    <AboutContainer id="about">
      <div>
        <Subtitle>SOBRE</Subtitle>
        <Title>O que somos e o que fazemos?</Title>
      </div>
      <div>
        <p>
        Somos a <span>Sapientia Soluções Inovadoras para Educação LTDA</span>, uma empresa dedicada à transformação educacional por meio da tecnologia. Nossa plataforma, a <span>SAPIEDUCA</span>, foi reconhecida internacionalmente como uma das <span>100 inovações educacionais mais impactantes e escaláveis do mundo</span>, durante a participação na <span>HundrED Innovation Summit</span>, que ocorreu na <span>Finlândia</span> em 2023.
        </p>

        <p>
        Descubra como podemos auxiliar em sua forma de avaliar e potencializar o aprendizado dos seus alunos:
        </p>
      </div>
    </AboutContainer>
  );
};

export default About;