/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Nav = styled.nav`
  height: 110px;
  padding: 42px 112px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    position: fixed;
    height: 100vh;
    background: white;
    z-index: 9999;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const MenuContainer = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-top: 24px;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

export const ButtonsContainer = styled.section`
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    height: auto;
    margin-top: 24px;
    flex-direction: column;
  }
`;

export const NavTransparentButton = styled.button`
  width: 174px;
  height: 44px;
  padding: 14px 0;
  border: none;
  color: #ea5f9e;
  background-color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  & > svg {
    margin-right: 11px;
  }
`;

export const NavButton = styled.a`
  width: 174px;
  height: 44px;
  background-color: #ea5f9e;
  border-radius: 24px;
  padding: 14px 0;
  border: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: white;
    filter: saturate(0.9);
  }

  & > svg {
    margin-right: 11px;
  }
`;

export const LinksList = styled.ul`
  width: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;

    & > a {
      margin-bottom: 8px;
    }
  }

  & > a:not(:last-child) {
    margin-right: 56px;
  }

  & li {
    list-style: none;

    & > a {
      text-decoration: none;
      color: #505050;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
    }
  }
`;
