import React from "react";

const Arrow = () => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.56"
        d="M1.29999 4.3002C0.802938 4.3002 0.399994 4.6136 0.399994 5.0002C0.399994 5.38679 0.802938 5.7002 1.29999 5.7002L12.1 5.7002C12.5971 5.7002 13 5.38679 13 5.0002C13 4.6136 12.5971 4.3002 12.1 4.3002L1.29999 4.3002Z"
        fill="white"
      />
      <path
        d="M7.60502 8.70517C7.33166 8.97854 7.33166 9.42175 7.60502 9.69512C7.87839 9.96849 8.32161 9.96849 8.59497 9.69512L12.795 5.49512C13.06 5.23011 13.0693 4.8034 12.816 4.52714L8.96601 0.327137C8.70477 0.0421534 8.26198 0.0229006 7.97699 0.284136C7.69201 0.54537 7.67276 0.988168 7.93399 1.27315L11.3311 4.97909L7.60502 8.70517Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;
