import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { BlogCardProps } from "./BlogCard.types";

const BlogCardItem = styled.div`
  display: flex;
  align-items: flex-start; /* Alinha os itens ao topo */
  justify-content: space-between; /* Distribui os itens horizontalmente */

  img {
    margin-right: 20px; /* Espaço entre a imagem e o texto */
    max-width: 40%; /* Largura da imagem */
  }

  div {
    flex: 1; /* Ocupa o espaço restante */
  }

  span {
    font-style: roboto;
    font-weight: 500;
    font-size: 17px;
    line-height: 184.9%;
    color: #35ccd3;
    margin-bottom: 10px; /* Espaço abaixo da data */
  }

  h3 {
    font-style: poppins;
    font-weight: 700;
    font-size: 24px;
    line-height: 138.7%;
    color: #3366cc;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #8c8a97;
  }
`;

const BlogCard: React.FC<BlogCardProps> = ({
  date,
  text,
  title,
  image,
  className
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)"
  });

  return (
    <BlogCardItem
      className={className}
      style={{ width: isMobile ? "100%" : "591px" }}
    >
      {image && <img src={image} alt={`${title} cover`} />}
      <div>
        <span>{date}</span>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </BlogCardItem>
  );
};

export default BlogCard;
