/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const BlogGridComponentContainer = styled.div`
  padding: 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px 10px;
  grid-template-areas:
    "content-with-image content-no-image-1"
    "content-with-image content-no-image-2";

  .content-with-image {
    grid-area: content-with-image;
  }
  .content-no-image-1 {
    grid-area: content-no-image-1;
  }
  .content-no-image-2 {
    grid-area: content-no-image-2;
  }
`;
