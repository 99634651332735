/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const SocialMediaContainer = styled.div`
  display: flex;
`;

export const SocialMediaButton = styled.a`
  display: flex;
  height: 34.84px;
  width: 34.84px;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 18px;
  }
`;
