/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const FooterContainer = styled.footer`
  height: 300px;
  margin-top: 253px;
  background-color: #3366cc;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 30px 112px 0;

  @media (max-width: 1024px) {
    padding: 3px 24px 0;
    height: auto;
    margin-top: 150px;

    .social-media {
      flex-direction: row;
    }
  }

  & > svg {
    position: absolute;
    top: -45%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      width: 100%;
      top: -34%;
    }
  }

  & > span {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;

    @media (max-width: 1024px) {
      font-size: 16px;
      padding-top: 16px;
    }
  }

  & > p {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
  }
`;

export const FooterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 1024px) {
    flex-direction: column;

    &:last-of-type {
      align-items: flex-start;
    }
  }

  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 184.9%;
    color: #ffffff;
  }
`;

export const FooterLinks = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: 90px;
    flex-wrap: wrap;
    width: 100%;
  }

  & a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-decoration: none;
  }

  & a:not(:last-of-type) {
    margin-right: 18px;
  }
`;
