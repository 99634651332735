import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Access from "../../../../assets/Access";
import Contact from "../../../../assets/Contact";
import Logo from "../../../../assets/Logo";
import {
  ButtonsContainer,
  LinksList,
  MenuContainer,
  Nav,
  NavButton,
  NavTransparentButton
} from "./Navbar.styles";
import { NavbarProps } from "./Navbar.types";

const Navbar: React.FC<NavbarProps> = ({ name, handleClose }) => {
  return (
    <Nav className={name}>
      <Logo />
      <MenuContainer>
        <ButtonsContainer>
          <Link
            activeClass="active"
            to="contact"
            spy
            smooth
            offset={100}
            duration={500}
          >
            <NavTransparentButton>
              <Contact />
              Contato
            </NavTransparentButton>
          </Link>
          <NavButton href="https://web.sapieduca.com.br" target="_blank">
            <Access />
            Acessar
          </NavButton>
        </ButtonsContainer>
        <LinksList>
          <Link
            activeClass="active"
            to="about"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Sobre</a>
            </li>
          </Link>
          <Link
            activeClass="active"
            to="solutions"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Soluções</a>
            </li>
          </Link>
          <Link
            activeClass="active"
            to="validation"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Validação</a>
            </li>
          </Link>
          <Link
            activeClass="active"
            to="prizes"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Prêmios</a>
            </li>
          </Link>
          <Link
            activeClass="active"
            to="depoimentos"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Equipe</a>
            </li>
          </Link>
          <Link
            activeClass="active"
            to="blog"
            spy
            smooth
            offset={100}
            duration={500}
            onClick={handleClose}
          >
            <li>
              <a href="/">Blog</a>
            </li>
          </Link>
        </LinksList>
      </MenuContainer>
    </Nav>
  );
};

export default Navbar;
