import React from "react";
import { BurgerContainer } from "./BurgerMenu.styles";
import { BurgerMenuProps } from "./BurgerMenu.types";

const BurgerMenu: React.FC<BurgerMenuProps> = ({ isOpen, handleClick }) => {
  return (
    <BurgerContainer className={isOpen ? "active" : ""} onClick={handleClick}>
      <div className="trace" />
      <div className="trace" />
      <div className="trace" />
    </BurgerContainer>
  );
};

export default BurgerMenu;
