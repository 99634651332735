import React from "react";

const Contact = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#EA5F9E" />
      <path
        d="M7.33332 7.33325H16.6667C17.3083 7.33325 17.8333 7.85825 17.8333 8.49992V15.4999C17.8333 16.1416 17.3083 16.6666 16.6667 16.6666H7.33332C6.69166 16.6666 6.16666 16.1416 6.16666 15.4999V8.49992C6.16666 7.85825 6.69166 7.33325 7.33332 7.33325Z"
        stroke="white"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8333 8.5L12 12.5833L6.16666 8.5"
        stroke="white"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Contact;
