import React, { useState, useEffect } from "react";
import ComputadorImg from "../../../assets/computador.png";
import RoundedIcon from "../../../assets/RoundedIcon";
import VideoPreview from "../../VideoPreview/VideoPreview";
import {
  GridItem,
  SectionContainer,
  SectionGrid,
  SectionTitle
} from "../OurProducts.styles";

const video = require("./video.mp4");

const SapientiaAnalytics = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <SectionContainer style={{ position: "relative", fontFamily: "Roboto" }}>
      <div style={{ position: "relative" }}>
        <SectionTitle style={{ fontFamily: "Poppins", color: "#3366CC" }}>Sapieduca Analytics</SectionTitle>
        <p>
        Descubra como o Sapieduca Analytics pode transformar a análise de dados na sua instituição de ensino! Nosso serviço de Learning Analytics oferece dashboards e relatórios desenvolvidos sob medida para atender às necessidades das instituições, utilizando os dados inseridos no Sapieduca.

        </p>
        <SectionGrid>
          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Médias</h3>
              <p>
              Tenha acesso a um dashboard com gráficos que apresentam as médias da instituição de ensino. Você pode filtrar as médias de diversas maneiras, incluindo média global, média por área de conhecimento, média por componente curricular, média por turma e média por aluno.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Frequência</h3>
              <p>
              Nosso dashboard também inclui gráficos para visualização da frequência dos estudantes. É possível aplicar filtros por frequência geral da instituição, por área de conhecimento, componente curricular, turma e aluno, proporcionando uma visão detalhada da frequência escolar.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Habilidades BNCC e NEM desenvolvidas</h3>
              <p>
              Visualize facilmente quais habilidades da Base Nacional Comum Curricular estão sendo plenamente desenvolvidas e quais necessitam de mais atenção, auxiliando os educadores a direcionar melhor seus esforços no desenvolvimento das competências dos estudantes.
              </p>
            </div>
          </GridItem>
        </SectionGrid>
      </div>

      
      {!isMobile && (
        <div style={{ position: "absolute", top: "10%", left: "70%" }}>
        
          <img
            src={ComputadorImg}
            alt="Computador"
            style={{ width: "100%", maxWidth: "600px", margin: "0 auto", position: "relative" }}
          />

         
          <div style={{ position: "absolute", top: "23%", left: "15%", width: "70%", height: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <VideoPreview
              src={video}
            />
          </div>
        </div>
      )}

     
      {isMobile && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <VideoPreview src={video} />
        </div>
      )}
    </SectionContainer>
  );
};

export default SapientiaAnalytics;
