import React from "react";
import { Carousel } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { BlogContainer, Subtitle, Title } from "./Blog.styles";
import ImageTest from "../../assets/test.png";
import BlogGridComponent from "../BlogGridComponent/BlogGridComponent";
import BlogCard from "../BlogCard/BlogCard";

const Blog = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 1024px)"
    });

    const addEllipsis = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    };
    
    return (
        <div style={{ marginTop: '150px' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}></div>
            <div style={{ marginBottom: '40px' }}>
                <BlogContainer id="blog">
                    <div>
                        <Subtitle>BLOG</Subtitle>
                        <Title>
                            Um pouco da nossa <br /> história
                        </Title>
                    </div>
                    {isMobile ? (
                        <Carousel style={{ width: "100%" }}>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogCard
                                        className="content-with-image"
                                        image={ImageTest}
                                        date="6 de Julho de 2022"
                                        title="Lorem Ipsum"
                                        text="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source." />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogCard
                                        className="content-no-image-1"
                                        date="6 de Julho de 2022"
                                        title="Rúbrica de Avaliação - Como utilizar esse instrumento avaliativo para melhorar os diagnósticos de aprendizagem?"
                                        text={addEllipsis("Rubrica é um sistema de classificação em que o professor determina os níveis de proficiência que um estudante desempenhou em uma tarefa, apresentação, desafio, evidência de conhecimento, entre outros. Leia mais no blog do Sapieduca!", 100)} />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogCard
                                        className="content-no-image-2"
                                        date="6 de Julho de 2022"
                                        title="Rúbrica de Avaliação - Como utilizar esse instrumento avaliativo para melhorar os diagnósticos de aprendizagem?"
                                        text={addEllipsis("Rubrica é um sistema de classificação em que o professor determina os níveis de proficiência que um estudante desempenhou em uma tarefa, apresentação, desafio, evidência de conhecimento, entre outros. Leia mais no blog do Sapieduca!", 100)} />
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    ) : (
                        <Carousel style={{ width: "100%" }}>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogGridComponent>
                                        <BlogCard
                                            className="content-with-image"
                                            image={ImageTest}
                                            date="6 de Julho de 2022"
                                            title="Lorem Ipsum"
                                            text="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source." />
                                        <BlogCard
                                            className="content-no-image-1"
                                            date="6 de Julho de 2022"
                                            title="Rúbrica de Avaliação - Como utilizar esse instrumento avaliativo para melhorar os diagnósticos de aprendizagem?"
                                            text={addEllipsis("Rubrica é um sistema de classificação em que o professor determina os níveis de proficiência que um estudante desempenhou em uma tarefa, apresentação, desafio, evidência de conhecimento, entre outros. Leia mais no blog do Sapieduca!", 100)} />

                                    </BlogGridComponent>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogGridComponent>
                                        <BlogCard
                                            className="content-with-image"
                                            image={ImageTest}
                                            date="6 de Julho de 2022"
                                            title="Lorem Ipsum"
                                            text="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source." />
                                        <BlogCard
                                            className="content-no-image-1"
                                            date="6 de Julho de 2022"
                                            title="Rúbrica de Avaliação - Como utilizar esse instrumento avaliativo para melhorar os diagnósticos de aprendizagem?"
                                            text={addEllipsis("Rubrica é um sistema de classificação em que o professor determina os níveis de proficiência que um estudante desempenhou em uma tarefa, apresentação, desafio, evidência de conhecimento, entre outros. Leia mais no blog do Sapieduca!", 100)} />

                                    </BlogGridComponent>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-block w-100">
                                    <BlogGridComponent>
                                        <BlogCard
                                            className="content-with-image"
                                            image={ImageTest}
                                            date="6 de Julho de 2022"
                                            title="Lorem Ipsum"
                                            text="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source." />
                                        <BlogCard
                                            className="content-no-image-1"
                                            date="6 de Julho de 2022"
                                            title="Rúbrica de Avaliação - Como utilizar esse instrumento avaliativo para melhorar os diagnósticos de aprendizagem?"
                                            text={addEllipsis("Rubrica é um sistema de classificação em que o professor determina os níveis de proficiência que um estudante desempenhou em uma tarefa, apresentação, desafio, evidência de conhecimento, entre outros. Leia mais no blog do Sapieduca!", 100)} />

                                    </BlogGridComponent>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    )}
                </BlogContainer>
            </div>
        </div>
    );
};

export default Blog;