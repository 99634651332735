/* eslint-disable import/prefer-default-export */
export default {
  colors: {
    primaryColor: "#3366CC",
    primaryColorDark: "#2A5EC7",
    secondaryColor: "#EA5F9E",
    tertiaryColor: "35CCD3",
    textColor: "#505050",
    textColorLight: "#7A7786",
    gray: "#505050",
    cardColor: "#F6F6F8"
  },
  fonts: ["sans-serif", "Poppins", "Inter", "Rubik"]
};
