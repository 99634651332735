import styled from "styled-components";

export const OurProductsContainer = styled.section`
  min-height: 671px;
  box-sizing: border-box;
  padding: 105px 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media (max-width: 1024px) {
    height: auto;
    padding: 105px 24px 0;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Subtitle = styled.span`
  font-style: roboto;
  font-weight: 500;
  font-size: 17px;
  line-height: 184.9%;
  color: #35ccd3;
  margin: 0;
`;

export const Title = styled.h2`
  font-style: roboto;
  font-weight: 700;
  font-size: 36px;
  line-height: 138.7%;
  color: #3366cc;
  margin: 0;
`;

export const SectionTitle = styled.h2`
  font-style: roboto;
  font-weight: 700;
  font-size: 36px;
  line-height: 138.7%;
  text-align: center;
  color: #505050;
  padding: 0;
`;

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > p {
      width: 685px;
      font-style: roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #505050;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`;

export const SectionGrid = styled.section`
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  width: 360px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & > div:first-of-type {
    margin-right: 22px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  & > div {
    & > h3 {
      margin: 0;
      font-style: Roboto;
      font-weight: 700;
      font-size: 16px;
      line-height: 128%;
      color: #505050;
    }

    & > p {
      font-style: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #505050;
    }
  }
`;

export const Button = styled.button`
  width: 384px;
  height: 44px;
  background-color: #ea5f9e;
  border-radius: 24px;
  padding: 14px 0;
  border: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 58px;
  transition: 0.3s ease-in-out;
  text-decoration: none;

  @media (max-width: 1024px) {
    width: 100%;
  }

  &:hover {
    filter: saturate(0.9);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 20px; /* Ajuste a margem conforme necessário */

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;
