import React from "react";

const Access = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#D94789" />
      <path
        d="M13.375 6.875H15.875C16.2065 6.875 16.5245 7.0067 16.7589 7.24112C16.9933 7.47554 17.125 7.79348 17.125 8.125V16.875C17.125 17.2065 16.9933 17.5245 16.7589 17.7589C16.5245 17.9933 16.2065 18.125 15.875 18.125H13.375"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 15.625L13.375 12.5L10.25 9.375"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.375 12.5H5.875"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Access;
