import React from "react";
import { ValidationContainer, Subtitle, Title } from "./Validation.styles";
import hexagons from "../../assets/hexagons.png";
import TeacherIcon from "../../assets/TeacherIcon";
import SchoolIcon from "../../assets/SchoolIcon";
import CityIcon from "../../assets/CityIcon";
import StudentIcon from "../../assets/StudentIcon";

const Validation = () => {
  return (
    <ValidationContainer id="validation">
      <div>
        <Subtitle>VALIDAÇÃO</Subtitle>
        <Title>Onde ja fazemos a diferença</Title>
        <img src={hexagons} alt="hexagons" />
      </div>
      <div className="parent">
        <div className="grid-item div1">
          <div>
            <TeacherIcon />
          </div>
          <div className="data">
            <p>131</p>
            <p>Professores</p>
          </div>
        </div>
        <div className="grid-item div2">
          <div>
            <CityIcon />
          </div>
          <div className="data">
            <p>08</p>
            <p>Cidades</p>
          </div>
        </div>
        <div className="grid-item div3">
          <div>
            <StudentIcon />
          </div>
          <div className="data">
            <p>2041</p>
            <p>Alunos</p>
          </div>
        </div>
        <div className="grid-item div4">
          <div>
            <SchoolIcon />
          </div>
          <div className="data">
            <p>09</p>
            <p>Escolas</p>
          </div>
        </div>
      </div>
    </ValidationContainer>
  );
};

export default Validation;
