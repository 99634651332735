import React from "react";
import {
  Facebook,
  Instagram
} from "../../assets/SocialMediaIcons";
import { SocialMediaButton, SocialMediaContainer } from "./SocialMedia.styles";

const SocialMedia = () => {
  return (
    <SocialMediaContainer>
      <SocialMediaButton>
        <Instagram />
      </SocialMediaButton>
      <SocialMediaButton>
        <Facebook />
      </SocialMediaButton>
    </SocialMediaContainer>
  );
};

export default SocialMedia;
