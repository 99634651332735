/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const ValidationContainer = styled.section`
  height: fit-content;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 152px 112px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding: 152px 24px 0;

    img {
      display: none;
    }
  }

  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  img {
    width: 90%;
  }

  .parent {
    width: min-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    .grid-item {
      background-color: #f6f6f8;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 18px;
      border-radius: 12px;

      & > div:first-of-type {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }

    .data {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      p {
        padding: 0;
        margin: 0;

        &:first-of-type {
          font-style: normal;
          font-weight: 400;
          font-size: 69.3333px;
          text-align: right;
          text-transform: uppercase;
          color: #3366cc;
          line-height: 69px;
        }

        &:last-of-type {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 201.5%;
          text-align: right;
          color: #505050;
        }
      }
    }

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
      width: 280px;
      height: auto;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .div2 {
      grid-area: 2 / 1 / 3 / 2;
      width: 280px;
      height: auto;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .div3 {
      grid-area: 3 / 1 / 4 / 3;
      height: auto;
      width: 100%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .div4 {
      grid-area: 1 / 2 / 3 / 3;
      height: 100%;
      width: 280px;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`;

export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 184.9%;
  color: #35ccd3;
  margin: 0;
`;

export const Title = styled.h2`
  width: 300px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 138.7%;
  color: #3366cc;
  margin: 0;
`;
