/* eslint-disable import/prefer-default-export */
import React from "react";

export const W = () => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8691 34.8447C27.4912 34.8447 35.2914 27.0444 35.2914 17.4223C35.2914 7.80024 27.4912 0 17.8691 0C8.24702 0 0.446777 7.80024 0.446777 17.4223C0.446777 27.0444 8.24702 34.8447 17.8691 34.8447Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2109 25.0634H18.5783C18.5783 25.0634 18.9914 25.0181 19.2022 24.7907C19.3963 24.582 19.3901 24.1901 19.3901 24.1901C19.3901 24.1901 19.3634 22.3552 20.215 22.085C21.0545 21.8189 22.1325 23.8584 23.275 24.6426C24.139 25.2361 24.7956 25.106 24.7956 25.106L27.8507 25.0634C27.8507 25.0634 29.4489 24.965 28.6911 23.7084C28.629 23.6056 28.2497 22.7789 26.4196 21.08C24.504 19.302 24.7605 19.5896 27.068 16.514C28.4734 14.641 29.0352 13.4975 28.8597 13.0077C28.6923 12.5412 27.6587 12.6645 27.6587 12.6645L24.2189 12.686C24.2189 12.686 23.9639 12.6512 23.7747 12.7642C23.59 12.8751 23.471 13.1335 23.471 13.1335C23.471 13.1335 22.9266 14.5829 22.2005 15.8156C20.6687 18.4166 20.0563 18.5539 19.806 18.3924C19.2237 18.016 19.369 16.8802 19.369 16.0733C19.369 13.5528 19.7513 12.5018 18.6246 12.2297C18.2506 12.1394 17.9755 12.0797 17.0192 12.0701C15.7919 12.0574 14.753 12.0738 14.1648 12.362C13.7735 12.5537 13.4716 12.9807 13.6555 13.0052C13.8828 13.0357 14.3977 13.1441 14.6707 13.5158C15.0232 13.9953 15.0108 15.0724 15.0108 15.0724C15.0108 15.0724 15.2133 18.0396 14.5378 18.4082C14.0741 18.661 13.4381 18.1448 12.0725 15.7851C11.3728 14.5764 10.8445 13.2403 10.8445 13.2403C10.8445 13.2403 10.7426 12.9906 10.5609 12.8571C10.3404 12.6953 10.0324 12.6437 10.0324 12.6437L6.76361 12.6652C6.76361 12.6652 6.27291 12.6788 6.09278 12.8922C5.93253 13.0819 6.08005 13.4745 6.08005 13.4745C6.08005 13.4745 8.63914 19.4617 11.5368 22.4788C14.1937 25.2451 17.2109 25.0634 17.2109 25.0634V25.0634Z"
      fill="#3366CC"
    />
  </svg>
);

export const Pinterest = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5111 34.8454C27.1332 34.8454 34.9334 27.0452 34.9334 17.4231C34.9334 7.80098 27.1332 0.000732422 17.5111 0.000732422C7.88899 0.000732422 0.0887451 7.80098 0.0887451 17.4231C0.0887451 27.0452 7.88899 34.8454 17.5111 34.8454Z"
      fill="white"
    />
    <path
      d="M18.9174 23.3315C17.6002 23.2297 17.0468 22.5765 16.0139 21.9495C15.4461 24.9282 14.7523 27.7835 12.6973 29.2752C12.0622 24.7732 13.6284 21.3917 14.3557 17.8025C13.1159 15.7154 14.5048 11.5147 17.1198 12.5498C20.3382 13.8225 14.3334 20.31 18.3646 21.1203C22.5731 21.9663 24.2912 13.8178 21.6811 11.1674C17.9102 7.34092 10.7047 11.0805 11.5907 16.5583C11.8066 17.8975 13.1905 18.304 12.1435 20.1519C9.7301 19.6174 9.0102 17.7139 9.10275 15.1763C9.25214 11.0224 12.8352 8.11393 16.4291 7.71143C20.9743 7.20272 25.2403 9.38043 25.8291 13.6557C26.4915 18.4811 23.7772 23.7067 18.9174 23.3315Z"
      fill="#3366CC"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4458 9.31152H13.0142C11.3491 9.31152 9.99451 10.6662 9.99451 12.3312V22.7628C9.99451 24.4278 11.3491 25.7829 13.0142 25.7829H23.4458C25.1108 25.7829 26.4659 24.4282 26.4659 22.7628V12.3312C26.4659 10.6662 25.1112 9.31152 23.4458 9.31152ZM18.23 22.97C15.2404 22.97 12.8079 20.5375 12.8079 17.5475C12.8079 14.5578 15.2404 12.1254 18.23 12.1254C21.2201 12.1254 23.6525 14.5578 23.6525 17.5475C23.6525 20.5371 21.2197 22.97 18.23 22.97ZM22.5436 11.963C22.5436 12.6703 23.1191 13.2459 23.8269 13.2459C24.5342 13.2459 25.1098 12.6703 25.1098 11.963C25.1098 11.2557 24.5342 10.6801 23.8269 10.6801C23.1191 10.6801 22.5436 11.2557 22.5436 11.963Z"
      fill="white"
    />
    <path
      d="M18.2302 14.4165C16.5043 14.4165 15.099 15.8211 15.099 17.5469C15.099 19.2736 16.5043 20.6785 18.2302 20.6785C19.9568 20.6785 21.361 19.2736 21.361 17.5469C21.361 15.8214 19.9564 14.4165 18.2302 14.4165Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.729797 17.5C0.729797 7.8354 8.5652 0 18.2298 0C27.8944 0 35.7298 7.8354 35.7298 17.5C35.7298 27.1646 27.8944 35 18.2298 35C8.5652 35 0.729797 27.1646 0.729797 17.5ZM23.446 28.0739C26.3748 28.0739 28.7569 25.6918 28.7569 22.763V12.3314C28.7569 9.40296 26.3748 7.02044 23.446 7.02044H13.0144C10.0859 7.02044 7.70343 9.40296 7.70343 12.3314V22.763C7.70343 25.6918 10.0859 28.0739 13.0144 28.0739H23.446Z"
      fill="white"
    />
  </svg>
);

export const Twitter = () => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7937 34.8447C27.4158 34.8447 35.2161 27.0444 35.2161 17.4223C35.2161 7.80024 27.4158 0 17.7937 0C8.17164 0 0.371399 7.80024 0.371399 17.4223C0.371399 27.0444 8.17164 34.8447 17.7937 34.8447Z"
      fill="white"
    />
    <path
      d="M29.4433 12.4812C28.6966 12.8122 27.8935 13.0362 27.0513 13.1362C27.9112 12.6209 28.5712 11.8057 28.8827 10.8327C28.078 11.31 27.1863 11.6563 26.2382 11.843C25.4785 11.0339 24.3962 10.5283 23.1977 10.5283C20.8982 10.5283 19.0333 12.3933 19.0333 14.6927C19.0333 15.0192 19.0702 15.3369 19.1417 15.6418C15.6807 15.4682 12.6119 13.8104 10.5578 11.2901C10.1994 11.9051 9.99384 12.6209 9.99384 13.384C9.99384 14.8285 10.7296 16.1037 11.8464 16.8503C11.1641 16.8288 10.5215 16.6416 9.9603 16.3288C9.95998 16.3465 9.95998 16.3642 9.95998 16.3816C9.95998 18.3994 11.3961 20.0824 13.3008 20.4647C12.9517 20.5603 12.5831 20.611 12.2039 20.611C11.9349 20.611 11.6743 20.5852 11.4203 20.5367C11.9501 22.1908 13.4877 23.3949 15.3105 23.4288C13.885 24.5459 12.0896 25.2114 10.1379 25.2114C9.80253 25.2114 9.47022 25.1919 9.14505 25.153C10.987 26.3351 13.1766 27.0242 15.5282 27.0242C23.1881 27.0242 27.377 20.6787 27.377 15.1751C27.377 14.9946 27.373 14.8148 27.3649 14.6365C28.1792 14.0496 28.8852 13.3163 29.4433 12.4812Z"
      fill="#3366CC"
    />
  </svg>
);

export const Facebook = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4353 34.8447C27.0574 34.8447 34.8577 27.0444 34.8577 17.4223C34.8577 7.80024 27.0574 0 17.4353 0C7.81324 0 0.0130005 7.80024 0.0130005 17.4223C0.0130005 27.0444 7.81324 34.8447 17.4353 34.8447Z"
      fill="white"
    />
    <path
      d="M21.8153 18.1047H18.7065V29.4939H13.9964V18.1047H11.7563V14.1021H13.9964V11.5119C13.9964 9.65969 14.8763 6.75928 18.7485 6.75928L22.2374 6.77387V10.6591H19.7059C19.2907 10.6591 18.7068 10.8666 18.7068 11.7501V14.1058H22.2268L21.8153 18.1047Z"
      fill="#3366CC"
    />
  </svg>
);
