/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from "react";
import Modal from "react-modal";
import { VideoPreviewContainer } from "./VideoPreview.styles";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    zindex: "999999999999",
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

interface VideoPreviewProps {
  src: string;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ src }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (Modal.defaultStyles.overlay && Modal.defaultStyles.content) {
      Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)";
      Modal.defaultStyles.overlay.zIndex = "9";
      Modal.defaultStyles.content.borderRadius = "30px";
    }
  }, []);

  return (
    <VideoPreviewContainer>
      <div className="hexagon" onClick={openModal}>
        <div className="arrow-right" />
      </div>
      <video width="100%" height="100%">
        <source src={src} type="video/mp4" />
      </video>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#222222",
            borderRadius: "30px"
          }}
        >
          <video
            width="100%"
            height="100%"
            controls
            style={{ borderRadius: "30px" }}
          >
            <source src={src} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </VideoPreviewContainer>
  );
};

export default VideoPreview;
