import React from "react";
import check from "../../assets/check.png";
import { Container, List } from "./TabContent.styles";

const Aluno: React.FunctionComponent = () => {
  const checkIconStyle = {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  };

  const containerStyle = {
    marginLeft: '10px',  
    color: "white",
    fontWeight: "bold"
  };

  const listItemTextStyle = {
    marginBottom: "4px"
   
  };

  const ulStyle = {
    marginTop: "20px"
  };

  return (
    <Container style={containerStyle}>
      <p>
        <strong>Os estudantes têm acesso ao seu perfil com todo o histórico de participação e habilidades desenvolvidas, podendo registrar sua jornada de aprendizagem em um portfólio digital. Além disso, eles podem participar do ranking do Top 7, baixar certificados, enfrentar desafios para aprimorar seus conhecimentos e registrar seu humor diário, tornando a experiência educacional mais envolvente e personalizada.</strong>
      </p>
      <List>
        <ul style={ulStyle}>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="check" style={checkIconStyle} />
            <p style={listItemTextStyle}><strong>Engajamento de pessoas</strong></p> 
          </li>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="check" style={checkIconStyle} />
            <p style={listItemTextStyle}><strong>Protagonismo</strong></p>
          </li>
        </ul>
        <ul style={ulStyle}>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="check" style={checkIconStyle} />
            <p style={listItemTextStyle}><strong>Conhecimento</strong></p>
          </li>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="check" style={checkIconStyle} />
            <p style={listItemTextStyle}><strong>Projeto de vida</strong></p>
          </li>
        </ul>
      </List>
    </Container>
  );
};

export default Aluno;
