/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FooterStickers from "../../assets/FooterStickers";
import Owl from "../../assets/Owl";
import SocialMedia from "../SocialMedia/SocialMedia";
import { FooterContainer, FooterLinks, FooterRow } from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterStickers /> 
      <span>Nossa turminha espera por você. Faça parte!</span>
      <FooterRow className="social-media">
        <div>
          <Owl />
        </div>
        <SocialMedia />
      </FooterRow>
      <FooterRow>
        <div>
          <span>Copyright © Sapieduca - Todos os direitos reservados</span>
        </div>
        <FooterLinks>
          <a href="#">Home</a>
          <a href="#">Sobre</a>
          <a href="#">Soluções</a>
          <a href="#">Validação</a>
          <a href="#">Prêmios</a>
          <a href="#">Equipe</a>
          <a href="#">Contato</a>
        </FooterLinks>
      </FooterRow>
    </FooterContainer>
  );
};

export default Footer;
