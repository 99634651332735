/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const TabContainer = styled.div`
  width: 606px;
  display: flex;
  border-radius: 8px;
  background-color: #e1e0e7;
  margin-top: 42px;
  margin-bottom: 60px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const Tab = styled.button<{ onClick: () => void }>`
  width: 50%;
  height: 51px;
  border: none;
  box-sizing: border-box;
  padding: 12px 20px;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e1e0e7;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;

  &.active {
    background: #35ccd3;
    width: 384px;

    @media (max-width: 1024px) {
      width: 50%;
    }

    & > span {
      font-style: roboto;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  & > span {
    font-style: roboto;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #222222;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
`;
