/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const BurgerContainer = styled.button`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 99999999999;
  position: fixed;
  right: 24px;
  top: 24px;
  border: 0;
  background-color: white;
  padding-bottom: 4px;
  box-sizing: initial;
  border-radius: 4px;

  & > .trace {
    width: 40px;
    height: 4px;
    background-color: #3366cc;
    position: absolute;
    border-radius: 4px;
    transition: 0.5s ease-in-out;
  }

  & > .trace:nth-child(1) {
    top: 15px;
    transform: rotate(0);
  }

  & > .trace:nth-child(2) {
    top: 26px;
    transform: rotate(0);
  }

  & > .trace:nth-child(3) {
    top: 38px;
    transform: rotate(0);
  }

  &.active {
    & > .trace:nth-child(1) {
      transform: rotate(45deg);
      top: 24px;
    }

    & > .trace:nth-child(2) {
      transform: translateX(-100px);
      width: 30px;
      visibility: hidden;
      opacity: 0;
    }
    & > .trace:nth-child(3) {
      transform: rotate(-45deg);
      top: 23px;
    }
  }
`;
