import React, { useState, useEffect } from "react";
import ComputadorImg from "../../../assets/computador.png";
import RoundedIcon from "../../../assets/RoundedIcon";
import VideoPreview from "../../VideoPreview/VideoPreview";
import {
  GridItem,
  SectionContainer,
  SectionGrid,
  SectionTitle
} from "../OurProducts.styles";

const video = require("./video.mp4");

const SapientiaMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <SectionContainer style={{ position: "relative", fontFamily: "Roboto" }}>
      <div style={{ position: "relative", zIndex: 2 }}>
        <SectionTitle style={{ fontFamily: "Poppins" }}>
          <span style={{ color: "#3366CC" }}>Sapieduca</span>
        </SectionTitle>
        <p>
        Ferramenta Gamificada de Avaliação Formativa, Engajamento e Projeto de Vida que disponibiliza instrumentos avaliativos e relatório de desempenho de forma facilitada. A inovação engaja os estudantes por meio dos recursos gamificados e da possibilidade de acompanhamento do desempenho acadêmico.
        </p>
        <SectionGrid>
          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Metodologias Ativas</h3>
              <p>
              Métodos de Aprendizagem Ativa onde o aluno é o protagonista da sua jornada, estando no centro de todo o processo. O uso dessas metodologias desenvolvem um jovem autônomo, curioso, protagonista e atuante na realização de seu projeto de vida.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Gamificação</h3>
              <p>
              A gamificação no Sapieduca é baseada no framework Octalysis, de forma a engajar os alunos e proporcionar novos usos metodológicos no processo de ensino. Contamos com os SapiensCoins ($C), o Top 7, as medalhas de realizações, o Boss (chefão) e muito mais.

              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Aprendizagem Ativa</h3>
              <p>
              O Sapieduca permite a criação e lançamento de desafios baseados na resolução de problemas que permitem avaliar as competências e habilidades da BNCC desenvolvidas pelos estudantes.
              </p>
            </div>
          </GridItem>

          <GridItem>
            <div>
              <RoundedIcon />
            </div>
            <div>
              <h3>Projeto de vida</h3>
              <p>
              Os professores possuem acesso aos Projetos de Vida dos estudantes e isso proporciona direcionamentos pedagógicos para a realização dos sonhos pessoais e profissionais dos jovens.

              </p>
            </div>
          </GridItem>
        </SectionGrid>
      </div>

      {!isMobile && (
        <div style={{ position: "absolute", top: "10%", left: "70%" }}>
          
          <img
            src={ComputadorImg}
            alt="Computador"
            style={{ width: "100%", maxWidth: "600px", margin: "0 auto", position: "relative" }}
          />

          
          <div style={{ position: "absolute", top: "23%", left: "15%", width: "70%", height: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <VideoPreview
              src={video}
            />
          </div>
        </div>
      )}

      {isMobile && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <VideoPreview src={video} />
        </div>
      )}
    </SectionContainer>
  );
};

export default SapientiaMobile;
