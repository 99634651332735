import React from "react";
import Arrow from "../../assets/Arrow";
import { Tab, TabsContainer } from "./TabNavigation.styles";
import { TabNavigationPros } from "./TabNavigation.types";

const TabNavigation: React.FunctionComponent<TabNavigationPros> = ({
  currentContent,
  setCurrentContent
}) => {
  return (
    <TabsContainer>
      <Tab
        onClick={() => setCurrentContent(0)}
        className={currentContent === 0 ? "active" : ""}
        style={{
          color: currentContent === 0 ? "#F23C8E" : "",
          backgroundColor: currentContent === 0 ? "#F23C8E" : ""
        }}
      >
        <span>Coordenador Pedagógico</span>
        <Arrow />
      </Tab>
      <Tab
        onClick={() => setCurrentContent(1)}
        className={currentContent === 1 ? "active" : ""}
        style={{
          color: currentContent === 1 ? "#F23C8E" : "",
          backgroundColor: currentContent === 1 ? "#F23C8E" : ""
        }}
      >
        <span>Professor</span>
        <Arrow />
      </Tab>
      <Tab
        onClick={() => setCurrentContent(2)}
        className={currentContent === 2 ? "active" : ""}
        style={{
          color: currentContent === 2 ? "#F23C8E" : "",
          backgroundColor: currentContent === 2 ? "#F23C8E" : ""
        }}
      >
        <span>Aluno</span>
        <Arrow />
      </Tab>
    </TabsContainer>
  );
};

export default TabNavigation;
