import React from "react";
import { TeamCardItem } from "./TeamCard.styles";
import { TeamCardProps } from "./Teamcard.types";

const TeamCard: React.FunctionComponent<TeamCardProps> = ({
  src,
  title,
  name,
  description
}) => {
  return (
    <TeamCardItem>
      <img src={src} alt="avatar" />
      <h3>{name}</h3>
      <p>{title}</p>
      <p>{description}</p>
    </TeamCardItem>
  );
};

export default TeamCard;
