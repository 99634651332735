import styled from "styled-components";

import previous from "../../assets/prev.png";
import next from "../../assets/next.png";

export const BlogContainer = styled.section`
  min-height: 671px;
  box-sizing: border-box;
  padding: 105px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1024px) {
    height: auto;
    padding: 0;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  & .carousel-indicators {
    bottom: -100px;
    opacity: 1;

    & > button {
      background: #8c8a97;
      opacity: 0.46;
      width: 7px;
      height: 7px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-radius: 100%;
    }

    & > button.active {
      background: #feda00;
    }
  }

  & .carousel-control-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    bottom: -100px;
    top: unset;
    left: 43%;
    background: #35ccd3;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    opacity: 1;

    @media (max-width: 1024px) {
      left: 25%;
    }
  }

  & .carousel-control-next {
    position: absolute;
    height: 40px;
    width: 40px;
    bottom: -100px;
    top: unset;
    left: 55%;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    opacity: 1;

    @media (max-width: 1024px) {
      left: 67%;
    }
  }

  & .carousel-control-prev-icon {
    background-image: url(${previous});
    width: 8px;
    height: 14px;
  }

  & .carousel-control-next-icon {
    background-image: url(${next});
    width: 8px;
    height: 14px;
  }
`;

export const Subtitle = styled.span`
  font-style: roboto;
  font-weight: 500;
  font-size: 17px;
  line-height: 184.9%;
  color: #35ccd3;
  margin: 0;
  
`;

export const Title = styled.h2`
  font-style: poppins;
  font-weight: 700;
  font-size: 36px;
  line-height: 138.7%;
  color: #3366cc;
  margin: 0;
  text-align: center;
  margin-bottom: 58px;
  
`;

export const ContentWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BlogCardImage = styled.img`
  margin-bottom: 20px; /* Espaço entre a imagem e o texto */
  margin-right: 20px; /* Espaço entre a imagem e o texto */
`;
